import { useMemo } from "react";
import {
  UseInfiniteQueryOptions,
  QueryKey,
  QueryFunction,
  useInfiniteQuery,
} from "react-query";

import { PaginatedResponse } from "./types";

export function useReducedInfiniteQuery<
  TResponse extends PaginatedResponse<any> = PaginatedResponse<unknown>,
  TEntity = TResponse extends PaginatedResponse<infer T> ? T : unknown
>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TResponse>,
  config?: UseInfiniteQueryOptions<TResponse>
) {
  const query = useInfiniteQuery<TResponse>(queryKey, queryFn, {
    getNextPageParam: (lastPage, allPages) => {
      const numPagesInCache = allPages.length;
      const nextPage = numPagesInCache + 1;

      return nextPage > lastPage.total_pages ? false : nextPage;
    },
    ...config,
  });

  const reducedData = useMemo(() => {
    return (
      query.data?.pages?.reduce((acc: TEntity[], page: TResponse) => {
        if (page?.records) {
          return [...acc, ...page["records"]];
        }
        return acc;
      }, []) ?? []
    );
  }, [query.data]);

  return { ...query, reducedData };
}
