import { useEffect } from "react";
import { Platform, findNodeHandle } from "react-native";

export function useWebEvent<TEvent = Event>(
  ref: any,
  eventName: string,
  onEvent: (e: TEvent) => void
) {
  useEffect(() => {
    let element: any;
    if (ref && Platform.OS === "web") {
      element = findNodeHandle(ref.current);

      element.addEventListener(eventName, onEvent);
    }

    return () => {
      if (ref && Platform.OS === "web" && element) {
        element.removeEventListener(eventName, onEvent);
      }
    };
  }, [onEvent, ref, eventName]);
}
