// Returns true if the given fn evaluates to a truthy value (neither false nor null) on all of the items in the array.
function all(values: Array<any>, fn: (item: any) => boolean) {
  return values.filter(fn).length === values.length;
}

// Returns true if the given fn evaluates to true on any of the items in the array.
function any(values: Array<any>, fn: (item: any) => boolean) {
  return values.filter(fn).length > 0;
}

function dedupeCollections<TOption = any>(
  data: TOption[],
  secondaryData: TOption[],
  getOptionLabel: (option: TOption) => string
) {
  const dataMap = data.reduce((acc, option) => {
    const optionLabel = getOptionLabel(option);
    return {
      ...acc,
      [optionLabel]: option,
    };
  }, {} as Record<string, TOption>);

  const optionsToMerge = secondaryData.reduce((acc, option) => {
    const optionLabel = getOptionLabel(option);

    if (!dataMap[optionLabel]) {
      return [...acc, option];
    }

    return acc;
  }, [] as TOption[]);

  return data.concat(optionsToMerge);
}

export const array = {
  all,
  any,
  dedupeCollections,
};
