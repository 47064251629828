import React, { useCallback } from "react";

import { ThemeType } from "./types";
import { createGlobalStyles } from "../../styles/global";
import { Platform } from "react-native";

export function GlobalStyles({ theme }: { theme: ThemeType }) {
  const getGlobalStyles = useCallback(
    () => ({
      __html: createGlobalStyles(theme),
    }),
    [theme]
  );

  return Platform.select({
    web: <style dangerouslySetInnerHTML={getGlobalStyles()} />,
    default: null,
  });
}
