// Validates the form to make sure all requirements are met
function validateForm(password: string, confirmPassword: string) {
  if (
    checkLength(password) &&
    checkLowerCase(password) &&
    checkUpperCase(password) &&
    checkSpecialChar(password) &&
    checkHasNumber(password) &&
    checkSamePasswords(password, confirmPassword)
  ) {
    return true;
  } else {
    return false;
  }
}

// Checks to make sure the password is at least 8 chars long
function checkLength(password: string) {
  if (password.length >= 8) {
    return true;
  } else {
    return false;
  }
}
// Checks to make sure the password has at least 1 lowercase
function checkLowerCase(password: string) {
  const pattern = new RegExp("(.*[a-z].*)");
  if (pattern.test(password)) {
    return true;
  } else {
    return false;
  }
}
// Checks to make sure the password has at least 1 uppercase
function checkUpperCase(password: string) {
  const pattern = new RegExp("(.*[A-Z].*)");
  if (pattern.test(password)) {
    return true;
  } else {
    return false;
  }
}
// Checks to make sure the password has at least 1 special char
function checkSpecialChar(password: string) {
  const pattern = new RegExp("(.*[\\W | _].*)");
  if (pattern.test(password)) {
    return true;
  } else {
    return false;
  }
}
// Checks to make sure password has at least 1 number
function checkHasNumber(password: string) {
  const pattern = new RegExp("(.*\\d.*)");
  if (pattern.test(password)) {
    return true;
  } else {
    return false;
  }
}
// Checks to make sure passwords are the same
function checkSamePasswords(password: string, confirmPassword: string) {
  return password === confirmPassword && password.length > 0;
}

export const password = {
  checkLength,
  checkLowerCase,
  checkUpperCase,
  checkSpecialChar,
  checkHasNumber,
  checkSamePasswords,
  validateForm,
};
