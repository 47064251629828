import { useCallback, useEffect, useState } from "react";
import { QueryObserverResult, RefetchOptions } from "react-query";

export function useQueryRefresh(query: {
  isFetched: boolean;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  [key: string]: any;
}): { refreshing: boolean; onRefresh: () => void } {
  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await query.refetch();
  }, [query]);

  useEffect(() => {
    if (refreshing && query.isFetched) {
      setRefreshing(false);
    }
  }, [refreshing, query.isFetched]);

  return {
    refreshing,
    onRefresh,
  };
}
