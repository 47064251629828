import { StyleSheet, TextStyle } from "react-native";

export const title = {
  one: { fontSize: 32, lineHeight: 38 },
  two: { fontSize: 24, lineHeight: 32 },
  three: { fontSize: 20, lineHeight: 28 },
  four: { fontSize: 18, lineHeight: 24 },
  five: { fontSize: 16, lineHeight: 20 },
  six: { fontSize: 14, lineHeight: 20 },
};
export const titleStyle = StyleSheet.create(title);

export const body = {
  normal: { fontSize: 16, lineHeight: 24 },
  small: { fontSize: 12, lineHeight: 20 },
  large: { fontSize: 20, lineHeight: 28 },
};
export const bodyStyle = StyleSheet.create(body);

export const caption = {
  normal: { fontSize: 12, lineHeight: 16 },
  small: { fontSize: 10, lineHeight: 16 },
  large: { fontSize: 14, lineHeight: 20 },
};
export const captionStyle = StyleSheet.create(caption);

export const listView: {
  sideAction: TextStyle;
} = {
  sideAction: {
    fontSize: 17,
    letterSpacing: -0.41,
    textAlign: "center",
    lineHeight: 20,
  },
};
export const listViewStyle = StyleSheet.create(listView);

export const shared = {
  controls: { fontSize: 24, letterSpacing: 0, lineHeight: 30 },
  // this should be used for: Controls/Table View/Cell Title
};
export const sharedStyle = StyleSheet.create(shared);
