export type HSV = Record<"h" | "s" | "v", number>;
export type HSL = Record<"h" | "s" | "l", number>;
export type RGB = Record<"r" | "g" | "b", number>;

export function rgbToHex({ r, g, b }: RGB): string {
  const rgb = (b | (g << 8) | (r << 16) | (1 << 24)).toString(16).slice(1);
  return `#${rgb.toString()}`;
}
export function hslToRgbObject({ h, s, l }: HSL): RGB {
  // Must be fractions of 1
  s /= 100;
  l /= 100;
  const c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;
  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);
  return { r, g, b };
}
export function hslToRgb(hsl: HSL) {
  const { r, g, b } = hslToRgbObject(hsl);
  return "rgb(" + r + "," + g + "," + b + ")";
}
export function hsvToRgbObject({ h, s, v }: HSV): RGB {
  let r, g, b;
  // Make sure our arguments stay in-range
  h = Math.max(0, Math.min(360, h));
  s = Math.max(0, Math.min(100, s));
  v = Math.max(0, Math.min(100, v));
  // We accept saturation and value arguments from 0 to 100 because that's
  // how Photoshop represents those values. Internally, however, the
  // saturation and value are calculated from a range of 0 to 1. We make
  // That conversion here.
  s /= 100;
  v /= 100;
  if (s == 0) {
    // Achromatic (grey)
    r = g = b = v;
    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255),
    };
  }
  h /= 60; // sector 0 to 5
  const i = Math.floor(h);
  const f = h - i; // factorial part of h
  const p = v * (1 - s);
  const q = v * (1 - s * f);
  const t = v * (1 - s * (1 - f));
  switch (i) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    default:
      // case 5:
      r = v;
      g = p;
      b = q;
  }
  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}
export function hsvToRgb(hsv: HSV): string {
  const { r, g, b } = hsvToRgbObject(hsv);
  return "rgb(" + r + "," + g + "," + b + ")";
}
export function rgbToHsvObject({ r, g, b }: RGB): HSV {
  let rr: number, gg: number, bb: number, s: number, v: number, diff: number;
  let h = 0;
  const rabs = r / 255;
  const gabs = g / 255;
  const babs = b / 255;
  (v = Math.max(rabs, gabs, babs)), (diff = v - Math.min(rabs, gabs, babs));
  const diffc = (c: number) => (v - c) / 6 / diff + 1 / 2;
  const percentRoundFn = (num: number) => Math.round(num * 100) / 100;
  if (diff == 0) {
    h = s = 0;
  } else {
    s = diff / v;
    rr = diffc(rabs);
    gg = diffc(gabs);
    bb = diffc(babs);
    if (rabs === v) {
      h = bb - gg;
    } else if (gabs === v) {
      h = 1 / 3 + rr - bb;
    } else if (babs === v) {
      h = 2 / 3 + gg - rr;
    }
    if (h < 0) {
      h += 1;
    } else if (h > 1) {
      h -= 1;
    }
  }
  return {
    h: Math.round(h * 360),
    s: percentRoundFn(s * 100),
    v: percentRoundFn(v * 100),
  };
}
export function hexToRgbObject(hex: string): RGB {
  hex = hex.substr(1);
  if (hex.length === 4) {
    hex = hex
      .split("")
      .reduce((acc, value) => acc.concat([value, value]), [] as string[])
      .join("");
  }
  const bigInt = parseInt(hex, 16);
  const r = (bigInt >> 16) & 255;
  const g = (bigInt >> 8) & 255;
  const b = bigInt & 255;
  return { r, g, b };
}
export function hexToRgb(hex: string): string {
  const { r, g, b } = hexToRgbObject(hex);
  return `rgb(${r}, ${g}, ${b})`;
}

export function hexToRgba(hex: string, alpha: number): string {
  const { r, g, b } = hexToRgbObject(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function colorBrightness(
  hex: string,
  mode: "light" | "dark",
  lum: number
): string {
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  if (mode === "dark") {
    lum = lum * -1;
  }

  let rgb = "#";
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

export function isValidHex(value: string): boolean {
  const match = value.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
  if (match && match[0]) {
    return true;
  }

  return false;
}
