import { RefObject, useEffect, useRef } from "react";

import { getScrollParent } from "@smartrent/utils";

export function useScrollLock(targetRef: RefObject<any>, locked: boolean) {
  const affected = useRef(false);
  useEffect(() => {
    const scrollParent = getScrollParent(targetRef.current);

    if (!scrollParent) {
      return;
    }

    const previousValue = scrollParent.style.getPropertyValue("overflow-y");
    if (locked) {
      affected.current = true;
      scrollParent.style.setProperty("overflow-y", locked ? "hidden" : "auto");
    }

    return () => {
      if (affected.current === true) {
        scrollParent.style.setProperty("overflow-y", previousValue);
      }
    };
  }, [locked, targetRef]);
}
