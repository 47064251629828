import React, {
  Ref,
  ComponentType,
  useMemo,
  forwardRef,
  ReactNode,
} from "react";
import {
  Pressable,
  PressableProps,
  AccessibilityProps,
  TouchableWithoutFeedbackProps,
  StyleSheet,
  View,
  StyleProp,
  TextStyle,
  ViewStyle,
  Platform,
} from "react-native";
import LinearGradient from "react-native-linear-gradient";
import { IconProps } from "@smartrent/icons";
import { colorBrightness } from "@smartrent/utils";

import { Text } from "../Text";
import { useTheme } from "../../utils/theme";
import { TestProps } from "../../types";

export type ButtonSize = "small" | "large";
export type ButtonVariation = "default" | "gradient" | "outlined" | "plain";

export interface ButtonProps extends AccessibilityProps, TestProps {
  contentStyle?: StyleProp<ViewStyle>; // Allow overriding the inner container <View /> styles
  disabled?: boolean;
  iconLeft?: ComponentType<IconProps>;
  iconRight?: ComponentType<IconProps>;
  linearGradientStyle?: StyleProp<ViewStyle>; // Allow overriding the <LinearGradient /> styles
  onPress?: TouchableWithoutFeedbackProps["onPress"];
  size?: ButtonSize;
  style?: StyleProp<ViewStyle>; // Allow overriding the <Pressable /> styles
  textStyle?: StyleProp<TextStyle>; // Allow overriding the <Text /> styles
  variation?: ButtonVariation;
  children: ReactNode;

  /**
   * @platform web
   */
  href?: string;
  /**
   * @platform web
   */
  target?: string;
  /**
   * @platform web
   */
  rel?: string;
  /**
   * @platform web
   */
  download?: string;
}

/**
 * A button component with default styles.
 *
 * example: https://smartrent-ui.com/components/general/button
 */
export const Button = forwardRef(function Button(
  {
    children,
    contentStyle,
    disabled = false,
    iconLeft: IconLeft,
    iconRight: IconRight,
    linearGradientStyle,
    onPress,
    size,
    style,
    testID,
    textStyle,
    variation = "default",
    href,
    rel,
    target,
    download,
    ...props
  }: ButtonProps,
  ref: Ref<View>
) {
  const { fonts, colors, shadows, mode, config } = useTheme();

  const normalizedSize = useMemo<ButtonSize>(() => {
    return size
      ? size
      : Platform.select<ButtonSize>({
          web: "small",
          default: "large",
        });
  }, [size]);

  const linkProps = Platform.select<PressableProps>({
    web: {
      href,
      hrefAttrs: {
        rel,
        target,
        download,
      },
    },
  });

  const sizeStyles = useMemo(() => {
    switch (normalizedSize) {
      case "large": {
        return {
          paddingVertical: 18,
          paddingHorizontal: 24,
        };
      }
      // defaults to the small size
      default: {
        return {
          paddingVertical: 10,
          paddingHorizontal: 24,
        };
      }
    }
  }, [normalizedSize]);

  const textColorStyles = useMemo(() => {
    switch (variation) {
      case "default":
      case "gradient": {
        if (disabled) {
          return {
            color: colors.gray400,
          };
        }
        return {
          color: colors.white,
        };
      }
      case "outlined": {
        if (disabled) {
          return {
            color: colors.gray400,
          };
        }
        return {
          color: colors.primary,
        };
      }
      case "plain": {
        if (disabled) {
          return {
            color: colors.gray400,
          };
        }
        return {
          color: colors.primary,
        };
      }
    }
  }, [variation, disabled, colors]);

  const textSizeStyles = useMemo(() => {
    switch (normalizedSize) {
      case "large": {
        return {
          fontSize: 16,
          lineHeight: 20,
        };
      }
      // defaults to the small size
      default: {
        return {
          fontSize: 14,
          lineHeight: 20,
        };
      }
    }
  }, [normalizedSize]);

  const buttonStyles = useMemo(() => {
    switch (variation) {
      case "default": {
        if (disabled) {
          return {
            backgroundColor: colors.animatedPlaceholder,
            borderWidth: 0,
          };
        }
        return {
          borderWidth: 0,
          backgroundColor: colors.primary,
          ...shadows.regular,
        };
      }
      case "gradient": {
        if (disabled) {
          return {
            backgroundColor: colors.animatedPlaceholder,
            borderWidth: 0,
          };
        }
        return {
          borderWidth: 0,
          ...shadows.regular,
        };
      }
      case "outlined": {
        if (disabled) {
          return {
            backgroundColor: "transparent",
            borderWidth: 2,
            borderColor: colors.gray400,
          };
        }
        return {
          backgroundColor: "transparent",
          borderWidth: 2,
          borderColor: colors.primary,
          ...shadows.regular,
        };
      }
      case "plain": {
        if (disabled) {
          return {
            backgroundColor: "transparent",
            borderWidth: 0,
          };
        }
        return {
          backgroundColor: "transparent",
          borderWidth: 0,
        };
      }
    }
  }, [variation, disabled, colors, shadows]);

  const content =
    typeof children === "string" ? (
      <View
        style={[
          styles.content,
          sizeStyles,
          {
            margin: variation === "outlined" ? -2 : 0, // Artificially remove 2px height/width because we'll have a border on all sides from the Pressable's getButtonStyles()
          },
          contentStyle,
        ]}
      >
        {IconLeft ? (
          <IconLeft
            size={20}
            {...textColorStyles} // Returns our color prop
            style={{
              marginRight: 12,
              marginLeft: -2, // visual hack to balance the content of buttons when using icons
            }}
          />
        ) : null}
        <Text
          style={[
            styles.text,
            {
              textTransform: config.uppercaseButtonLabel ? "uppercase" : "none",
            },
            fonts.semibold,
            textColorStyles,
            textSizeStyles,
            textStyle,
          ]}
        >
          {children}
        </Text>
        {IconRight ? (
          <IconRight
            size={20}
            {...textColorStyles} // Returns our color prop
            style={{
              marginLeft: 12,
              marginRight: -2, // visual hack to balance the content of buttons when using icons
            }}
          />
        ) : null}
      </View>
    ) : (
      children
    );

  return (
    <Pressable
      accessibilityRole={href ? "link" : "button"}
      onPress={onPress}
      style={({ pressed, hovered }) => [
        styles.root,
        buttonStyles,
        {
          backgroundColor:
            pressed && buttonStyles.backgroundColor && variation === "default"
              ? colorBrightness(buttonStyles.backgroundColor, mode, 0.1)
              : Platform.OS === "web" &&
                hovered &&
                buttonStyles.backgroundColor &&
                variation === "default"
              ? colorBrightness(buttonStyles.backgroundColor, mode, 0.1)
              : buttonStyles.backgroundColor,
        },
        style,
      ]}
      disabled={disabled}
      testID={testID}
      ref={ref}
      {...props}
      {...linkProps}
    >
      {variation === "gradient" && !disabled ? (
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
          colors={[colors.secondary, colors.primary, colors.tertiary]}
          locations={[0, 0.5, 1]}
          style={[
            Platform.OS === "web"
              ? {
                  flex: 1,
                }
              : { width: "100%" },
            linearGradientStyle,
          ]}
        >
          {content}
        </LinearGradient>
      ) : (
        content
      )}
    </Pressable>
  );
});

Button.displayName = "Button";

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    overflow: "hidden", // Force the borderRadius crop on Touchable descendents (specifically LinearGradient)
  },
  content: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    textTransform: "uppercase",
  },
});
