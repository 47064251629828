import axios from "axios";

export const instance = axios.create();

instance.interceptors.response.use(
  function (response: any) {
    return response.data;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);
