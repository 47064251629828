import { useCallback, useState } from "react";
import type { LayoutChangeEvent } from "react-native";

export function useLayout() {
  const [layout, setLayout] = useState<
    { x: number; y: number; width: number; height: number } | undefined
  >();
  const onLayout = useCallback<(event: LayoutChangeEvent) => void>((event) => {
    setLayout(event.nativeEvent.layout);
  }, []);

  return {
    layout,
    onLayout,
  };
}
