import { ThemeType } from "../utils/theme/types";

export function createGlobalStyles({ colors, fonts }: ThemeType) {
  return `
  @keyframes onAutoFillStart {
    from {
      /**/
    }
    to {
      /**/
    }
  }
  
  @keyframes onAutoFillCancel {
    from {
      /**/
    }
    to {
      /**/
    }
  }
  
  input:-webkit-autofill::first-line {
    font-family: ${fonts.regular.fontFamily};
    font-size: 16px;
  }
  
  input:-webkit-autofill {
    -webkit-text-fill-color: ${colors.textPrimary};
    animation-name: onAutoFillStart;
    transition: background-color 50000s ease-in-out 0s;
  }
  
  input:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
  }  
  `;
}
