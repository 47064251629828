export function isFalsey(
  input: number | string | undefined | null | boolean
): boolean {
  if (typeof input === "number" && !isNaN(input)) return false;
  if (typeof input === "string" && input !== "") return false;
  if (input === true) return false;
  return true;
}

export function isTruthy(
  input: number | string | undefined | null | boolean
): boolean {
  return !isFalsey(input);
}
