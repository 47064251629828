import { get } from "lodash-es";
import { PropsWithChildren } from "react";

export const propsAreEqual = (
  compare: Array<any>,
  prev: Readonly<PropsWithChildren<any>>,
  next: Readonly<PropsWithChildren<any>>
) => {
  const defaults = ["item.id", "item.label", "item.value"];

  if (prev && next) {
    compare = compare ? compare : defaults;
  } else {
    prev = compare;
    next = prev;
    compare = defaults;
  }

  return (
    compare.filter((path) => {
      return get(prev, path) === get(next, path);
    }).length === compare.length
  );
};
