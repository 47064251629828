import {
  isValid,
  format,
  formatDistance,
  startOfWeek,
  addDays,
} from "date-fns";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";

import { DayOfWeekIndex } from "@smartrent/types";

export const getCreatedAt = (
  { created_at }: { created_at: string },
  { timezone }: { timezone: string },
  formatPattern: string = "PPPPpp"
) => {
  if (!isValid(new Date(created_at))) {
    return "";
  }
  return format(utcToZonedTime(created_at, timezone), formatPattern);
};

export const getCreatedAtFromNow = ({
  created_at,
}: {
  created_at: string | number;
}) => {
  if (!isValid(new Date(created_at))) {
    return "";
  }
  return formatDistance(new Date(created_at), new Date());
};

export function getWeekDayNamesAbbr({
  startOfWeekDayIndex = 0,
  formatStr = "EEEEE",
}: {
  startOfWeekDayIndex?: DayOfWeekIndex;
  formatStr?: string;
}): string[] {
  const startOfWeekDay = startOfWeek(new Date(), {
    weekStartsOn: startOfWeekDayIndex,
  });
  return Array.from(new Array(7), (_day, index) => {
    return format(addDays(startOfWeekDay, index), formatStr);
  });
}
