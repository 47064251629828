import { RefObject, useCallback, useEffect } from "react";

export function useClickOutside({
  visible,
  onClose,
  captureEvent = true,
  refs,
}: {
  visible: boolean;
  onClose: VoidFunction;
  captureEvent?: boolean;
  refs: RefObject<any>[];
}) {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      let shouldClose = false;

      if (refs.length === 1) {
        const el = refs[0].current;
        shouldClose = el === e.target;
      } else {
        shouldClose = refs.every((ref, index) => {
          const el = ref?.current;
          if (!el || el.contains(e.target)) {
            return false;
          }
          return true;
        });
      }

      if (shouldClose) {
        onClose();
      }
    },
    [onClose, refs]
  );

  useEffect(() => {
    if (visible) {
      document.addEventListener("click", handleClick, {
        passive: true,
        capture: captureEvent,
      });
    }
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [visible, handleClick, captureEvent]);
}
