import { useCallback, useMemo } from "react";
import { QueryFunction, QueryKey, UseInfiniteQueryOptions } from "react-query";

import { useReducedInfiniteQuery } from "./infinite";
import { PaginatedResponse } from "./types";

import { useQueryRefresh } from "../useQueryRefresh";

export function useFlatListQuery<
  TResponse extends PaginatedResponse<any> = PaginatedResponse<unknown>,
  TEntity = TResponse extends PaginatedResponse<infer T> ? T : unknown
>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TResponse>,
  config?: UseInfiniteQueryOptions<TResponse>
) {
  const query = useReducedInfiniteQuery<TResponse, TEntity>(
    queryKey,
    queryFn,
    config
  );

  const onEndReached = useCallback(() => {
    if (query.hasNextPage) {
      query.fetchNextPage();
    }
  }, [query]);

  const totalRecordsLoaded = useMemo(() => {
    return query.reducedData.length;
  }, [query.reducedData]);

  const totalRecords = useMemo(() => {
    if (query.data && query.data.pages[0]) {
      return query.data?.pages[0].total_records;
    }

    return 0;
  }, [query.data]);

  const totalPages = useMemo(() => {
    if (query.data && query.data.pages[0]) {
      return query.data?.pages[0].total_pages;
    }

    return 0;
  }, [query.data]);

  const { refreshing, onRefresh } = useQueryRefresh(query);

  return {
    // we're not using isFetching as it would trigger a skeleton/loading state when the queries are stale.
    // we're not using isLoading as it isn't set to true and first (since the query hasn't kicked off) and that isn't what we want.
    isLoading: query.isSuccess === false,
    totalPages,
    totalRecords,
    totalRecordsLoaded,
    onRefresh,
    refreshing,
    onEndReached,
    data: query.reducedData,
  };
}
