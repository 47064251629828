import React from "react";
import { Animated, StyleProp, ViewStyle } from "react-native";

import { AnimatedPlaceholder } from "../AnimatedPlaceholder";
import { TextType } from "../Text";

import { useTheme } from "../../utils/theme";
import { body, title, caption } from "../../styles/text";
import { TestProps } from "../../types";

type Style = Animated.WithAnimatedValue<StyleProp<ViewStyle>>;

export interface SkeletonBaseProps extends TestProps {
  style?: Style;
  height?: number;
}
export interface TextSkeletonProps extends SkeletonBaseProps {
  textType?: TextType;
  width?: number;
}
export interface CircleSkeletonProps extends SkeletonBaseProps {
  circle: boolean;
  width: number;
}
export interface SkeltonCombinedProps extends SkeletonBaseProps {
  width?: number;
  circle?: boolean;
  textType?: TextType;
}

const styleMap: Record<TextType, { fontSize: number; lineHeight: number }> = {
  body: body.normal,
  bodySmall: body.small,
  bodyLarge: body.large,
  bodySemibold: body.normal,
  bodySmallSemibold: body.small,
  bodyLargeSemibold: body.large,
  title: title.one,
  title2: title.two,
  title3: title.three,
  title4: title.four,
  title5: title.five,
  title6: title.six,
  titleBold: title.one,
  title2Bold: title.two,
  title3Bold: title.three,
  title4Bold: title.four,
  title5Bold: title.five,
  title6Bold: title.six,
  caption: caption.normal,
  captionSmall: caption.small,
  captionLarge: caption.large,
  captionSemibold: caption.normal,
  captionSmallSemibold: caption.small,
  captionLargeSemibold: caption.large,
};

function getTextStyle(
  textType: TextType
): { height: number; marginVertical: number } | undefined {
  const style = styleMap[textType];

  if (style) {
    return {
      height: style.fontSize,
      marginVertical: (style.lineHeight - style.fontSize) / 2,
    };
  }

  return undefined;
}

/**
 * A primitive for building skeleton loaders.
 *
 * example: https://smartrent-ui.com/components/general/skeleton
 */
export function Skeleton({
  style,
  textType,
  width,
  height,
  testID,
}: TextSkeletonProps): React.ReactElement;
export function Skeleton({
  style,
  circle,
  width,
  height,
  testID,
}: CircleSkeletonProps): React.ReactElement;
export function Skeleton({
  width,
  height,
  circle,
  textType,
  style,
  testID,
}: SkeltonCombinedProps) {
  const { colors } = useTheme();

  if (circle && width) {
    height = width;
  }

  const styles: Style = [
    {
      width,
      height,
      borderRadius: circle && width ? width / 2 : undefined,
      backgroundColor: colors.animatedPlaceholder,
    },
    textType ? getTextStyle(textType) : undefined,
    style,
  ];

  return <AnimatedPlaceholder testID={testID} style={styles} />;
}
