import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Platform } from "react-native";
import {
  ThemeMode,
  ThemeBuilderType,
  ThemeContextType,
} from "@smartrent/tokens";

import { themes } from "./themes";
import { GlobalStyles } from "./GlobalStyles";

const defaultTheme = "smartrent";
const defaultMode = "light";

export const ThemeContext = createContext<ThemeContextType>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!
);

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({
  theme = themes[defaultTheme],
  initialMode = defaultMode,
  children,
}: {
  theme?: ThemeBuilderType;
  initialMode?: ThemeMode;
  children: any;
}) => {
  const [mode, setMode] = useState<ThemeMode>(() => initialMode);
  const themeBuilder = useRef(theme).current;
  const [builtTheme, setBuiltTheme] = useState(() => theme(mode, Platform.OS));

  const { isDarkMode, isLightMode } = useMemo(() => {
    return {
      isDarkMode: mode === "dark",
      isLightMode: mode === "light",
    };
  }, [mode]);

  useEffect(() => {
    // on native the initialMode may take a second to change since it is being loaded via an async call
    if (Platform.OS !== "web") {
      setMode(initialMode as ThemeMode);
    }
  }, [initialMode]);

  const setTheme = useCallback(
    (theme: ThemeBuilderType) => {
      setBuiltTheme(theme(mode, Platform.OS));
    },
    [mode]
  );

  useEffect(() => {
    setTheme(themeBuilder);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to rebuild the theme on mode changes
  }, [mode]);

  return (
    <ThemeContext.Provider
      value={{
        ...builtTheme,
        mode: mode,
        setTheme,
        setMode,
        isDarkMode,
        isLightMode,
      }}
    >
      <GlobalStyles theme={builtTheme} />
      {children}
    </ThemeContext.Provider>
  );
};
export const ThemeConsumer = ThemeContext.Consumer;
