import React, { ReactNode, useEffect, useRef } from "react";
import { Animated, Platform, StyleProp, ViewStyle } from "react-native";

import { TestProps } from "../../types";

export interface AnimatedPlaceholderProps extends TestProps {
  children?: ReactNode;
  style?: Animated.WithAnimatedValue<StyleProp<ViewStyle>>;
}

export function AnimatedPlaceholder({
  style,
  children,
  testID,
}: AnimatedPlaceholderProps) {
  const fadeAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnimation, {
          toValue: 1,
          duration: 600,
          useNativeDriver: Platform.OS === "web" ? false : true,
        }),
        Animated.timing(fadeAnimation, {
          toValue: 0,
          duration: 600,
          useNativeDriver: Platform.OS === "web" ? false : true,
        }),
      ])
    ).start();
  }, [fadeAnimation]);

  return (
    <Animated.View
      testID={testID}
      style={[
        style,
        {
          opacity: fadeAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [0.4, 1],
          }),
        },
      ]}
    >
      {children}
    </Animated.View>
  );
}
