import React from "react";
import Svg, { Path } from "svgs";
import { View } from "react-native";
import { IconProps } from "./types";

export function ExclamationCircleOutline({
  size = 24,
  color = "currentcolor",
  style,
  ...props
}: Omit<IconProps, "width" | "height" | "viewBox" | "fill">) {
  const component = (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M12 15.218a.513.513 0 00.513-.513V6.17a.513.513 0 00-1.026 0v8.535a.513.513 0 00.513.513zM12.855 17.562a.855.855 0 11-1.71 0 .855.855 0 011.71 0z"
        fill={color}
      />
      <Path
        d="M19.071 4.929A10 10 0 104.928 19.072 10 10 0 0019.07 4.93zM12 20.977c-4.95 0-8.98-4.027-8.98-8.98 0-4.951 4.028-8.979 8.98-8.979 4.952 0 8.98 4.028 8.98 8.98 0 4.952-4.03 8.98-8.98 8.98z"
        fill={color}
      />
    </Svg>
  );

  if (style) {
    return <View style={style}>{component}</View>;
  }

  return component;
}
