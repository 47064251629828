import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from "react-query";

/**
 * @deprecated
 */
export interface CompatQueryFunction<
  TQueryKey extends readonly unknown[] = readonly unknown[],
  TData = unknown
> {
  (...queryKey: TQueryKey): TData | Promise<TData>;
}

/**
 * useQueryCompat provides compatibility with the react-query v2 API.
 *
 * @deprecated Prefer createAxiosQuery or useQuery.
 *
 * @param queryKey - Query key.
 * @param queryFn - A function that performs the actual query (typically an API call).
 * @param options - Query options.
 */
export function useQueryCompat<
  TQueryKey extends readonly unknown[] = readonly unknown[],
  TData = unknown,
  TError extends Error = Error
>(
  queryKey: readonly [...TQueryKey],
  queryFn: CompatQueryFunction<[...TQueryKey], TData>,
  options?: UseQueryOptions<TData, TError>
) {
  return useQuery(
    queryKey as any,
    ({ queryKey }) => {
      if (Array.isArray(queryKey)) {
        return (queryFn as any)(...queryKey);
      }
      return (queryFn as any)(queryKey);
    },
    options
  );
}

/**
 * @deprecated
 */
export interface CompatMutationFunction<TData = unknown, TVariables = unknown> {
  (variables: TVariables): Promise<TData>;
}

/**
 * useMutationCompat provides compatibility with the react-query v2 API.
 *
 * @deprecated Prefer createAxiosMutation or useMutation.
 *
 * @param mutateFn - A function that performs the actual mutation (typically an API call).
 * @param options - Query options.
 */
export function useMutationCompat<
  TData = unknown,
  TVariables = unknown,
  TError extends Error = Error,
  TContext = unknown
>(
  mutateFn: CompatMutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>
) {
  const { mutate, mutateAsync, ...rest } = useMutation(mutateFn, options);

  return [mutateAsync, rest] as const;
}
